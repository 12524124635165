.page {
    display: flex;
    flex-direction: column;
    gap: 5rem;
}

.section {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chaptersSection__images {
    display: flex;
}

.chaptersSection__container {
}

.chaptersSection__image {
    max-width: 100%;
}

.image {
    width: 90%;
}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.navbar__list {
    display: flex;
    gap: 1.5rem;
    justify-content: space-between;
    font-size: 1.75rem;
}

.navbar__link {
    flex-shrink: 1;
}

.link {
    text-decoration: none;
    color: var(--text-color);
}

.descriptionSection__text {
    font-size: 1.25rem;
    margin: 1rem 0 1rem;
}

.descriptionSection__article {
    width: 90%;
}

.gallerySection {
    align-items: flex-start;
    padding-left: 5%;
}

.title {
    font-size: 3rem;
}

.gallerySection__item {
    font-size: 1.5rem;
}

.section:last-of-type {
    margin-bottom: 3rem;
}
