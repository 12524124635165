.page {
    padding: 0 20%;
 }

.logo {
    max-width: 50%;
}

.logo__outer {
    width: 100%;
    display: flex;
    justify-content: center;

    margin-bottom: 2rem;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 50px 1.8rem;
}

.gallery__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
